import { updateObject } from '../../utility';

const initFormData = {
    name: {
        value: ''
    },
    email: {
        value: ''
    },
    birthday: {
        value: '',
    },
    passportStatus: {
        value: ''
    },
    note: {
        value: ''
    }
};
const initialState = {
    formData: initFormData,
    isSubmited: false
}

const setInit = (state, action) => {
    let formData = {...state.formData};
    formData['name'] = {...formData['vipLevel'], ...{value: action.item.name}};
    formData['email'] = {...formData['email'], ...{value: action.item.email}};
    formData['birthday'] = {...formData['birthday'], ...{value: (action.item.bday)?action.item.bday:''}};
    formData['passportStatus'] = {...formData['passportStatus'], ...{value: action.item.passportphotostatus}};
    formData['note'] = {...formData['note'], ...{value: (action.item.note)? action.item.note:''}};

    return updateObject(state, {formData, isSubmited: false});
}

const inputChanged = (state, action) => {
    let identifier = action.payload.identifier;
    let value = action.payload.value;
    let formData = {...state.formData};
    formData[identifier] = {...formData[identifier], ...{value}};

    return updateObject(state, {formData, isSubmited: false});
}

const setIsSubmit = (state, action) => {
    return updateObject(state, {isSubmited: action.value});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'PROFILE_INIT': return setInit(state, action);
        case 'PROFILE_INPUT_CHANGED': return inputChanged(state, action);
        case 'PROFILE_SUBMIT_SUCCESS': return setIsSubmit(state, action);
        default: return state;
    }
};

export default reducer;