import { updateObject } from '../utility';

const initialState = {
    data: null,
    formData: [],
    isSubmit: false,
    isAwcMemberCreated: false,
    awcMemberBalance: null,
    isAwcLogout: false,
}

const setData = (state, action) => {
    return updateObject(state, {data: action.data});
}

const initForm = (state, action) => {
    let formData = [...state.formData];
    for (let key in action.data) {
        formData[key] = {
            id: action.data[key].id,
            gameDbId: action.data[key].game.id,
            gameName: action.data[key].game.name,
            gameId: action.data[key].gameid,
            password: action.data[key].gamepassword
        }
    }
    return updateObject(state, {formData});
}

const inputChanged = (state, action) => {
    let key = action.payload.key;
    let identifier = action.payload.identifier;
    let value = action.payload.value;
    let formData = [...state.formData];
    formData[key][identifier] = value;

    return updateObject(state, {formData});
}

const setIsSubmit = (state, action) => {
    return updateObject(state, {isSubmited: action.value});
}

const setIsAwcMemberCreated = (state, action) => {
    return updateObject(state, {isAwcMemberCreated: action.value});
}

const setAwcMemberBalance = (state, action) => {
    return updateObject(state, {awcMemberBalance: action.value});
}

const setIsAwcLogout = (state, action) => {
    return updateObject(state, {isAwcLogout: action.value});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SET_USER_GAME_ID_DATA': return setData(state, action);
        case 'SET_USER_GAME_ID_INIT_FORM': return initForm(state, action);
        case 'USER_GAME_ID_INPUT_CHANGED': return inputChanged(state, action);
        case 'USER_GAME_ID_SUBMIT_SUCCESS': return setIsSubmit(state, action);
        case 'AWC_CREATE_MEMBER_SUCCESS': return setIsAwcMemberCreated(state, action);
        case 'AWC_MEMBER_BALANCE_DATA': return setAwcMemberBalance(state, action);
        case 'AWC_LOGOUT_SUCCESS': return setIsAwcLogout(state, action);
        default: return state;
    }
};

export default reducer;
