import { updateObject } from '../utility';

const initialState = {
    data: null,
}

const setUserDetail = (state, action) => {
    return updateObject(state, {data: action.userDetail});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SET_USER_DETAIL_DATA': return setUserDetail(state, action);
        default: return state;
    }
};

export default reducer;