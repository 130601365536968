// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
import calenderReducer from "./calenderReducer";
import emailReducer from "./email/";
// import chatReducer from "./chatReducer";
import chatReducer from "./chat/";
import contactsReducer from "./contacts/";
import todoReducer from "./todo/";
import customizer from "./customizer/";
import authReducer from "./auth";
import configReducer from "./config";
import usersReducer from "./users/";
import notificationReducer from "./notification/";

import userReducer from '../user/userReducer';
import addUserReducer from '../user/addUserReducer';
import bannUserReducer from '../user/bannUserReducer';
import editUserReducer from '../user/editUserReducer';
import userTypeReducer from '../userType/userTypeReducer';
import vipLevelReducer from '../vipLevel/vipLevelReducer';
import dialCodeReducer from '../dialCode/dialCodeReducer';
import userDetailReducer from '../userDetail/userDetailReducer';
import profileReducer from '../userDetail/profile/profileReducer';
import resetPasswordReducer from '../userDetail/resetPassword/resetPasswordReducer';
import userGameIdReducer from '../userGameId/userGameIdReducer';
import userBankAccountReducer from '../userDetail/bankAccount/bankAccountReducer';
import generalReducer from '../general/generalReducer';
import bankAccountReducer from '../bankAccount/bankAccountReducer';
import depositReducer from '../deposit/depositReducer';

import { reducer as toastrReducer } from "react-redux-toastr";

const rootReducer = combineReducers({
   calender: calenderReducer,
   emailApp: emailReducer,
   contactApp: contactsReducer,
   todoApp: todoReducer,
   toastr: toastrReducer, // <- Mounted at toastr.
   chatApp: chatReducer,
   customizer: customizer,
   auth: authReducer,
   config: configReducer,
   user: userReducer,
   addUser: addUserReducer,
   bannUser: bannUserReducer,
   editUser: editUserReducer,
   userApp: usersReducer,
   userType: userTypeReducer,
   vipLevel: vipLevelReducer,
   dialCode: dialCodeReducer,
   userDetail: userDetailReducer,
   profil: profileReducer,
   resetPassword: resetPasswordReducer,
   notifApp: notificationReducer,
   userGameId: userGameIdReducer,
   userBankAccount: userBankAccountReducer,
   general: generalReducer,
   bankAccount: bankAccountReducer,
   deposit: depositReducer
});

export default rootReducer;
