import { combineReducers } from "redux";
import users from "./users";
import usersDetails from "./usersDetails";
import usersSearch from "./usersSearch";
import editUser from "./editUser";

export default combineReducers({
   users,
   usersDetails,
   usersSearch,
   editUser
});
