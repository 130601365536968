import { updateObject } from '../utility';

const initFormData = {
    name: {
        value: ''
    },
    dialCode: {
        value: ''
    },
    phone: {
        value: '',
    },
    email: {
        value: ''
    },
    password: {
        value: ''
    }
};
const initialState = {
    formData: initFormData,
    isSubmited: false
}

const setInit = (state) => {
    return updateObject(state, {formData: initFormData, isSubmited: false});
}

const inputChanged = (state, action) => {
    let identifier = action.payload.identifier;
    let value = action.payload.value;
    let formData = {...state.formData};
    formData[identifier] = {...formData[identifier], ...{value}};

    return updateObject(state, {formData});
}

const setIsSubmit = (state, action) => {
    return updateObject(state, {formData: initFormData, isSubmited: action.value});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'ADD_USER_INIT': return setInit(state);
        case 'ADD_USER_INPUT_CHANGED': return inputChanged(state, action);
        case 'ADD_USER_SUBMIT_SUCCESS': return setIsSubmit(state, action);
        default: return state;
    }
};

export default reducer;