import { updateObject } from '../utility';

const initialState = {
    bankData: null,
    bankAccountStatuses: null
}

const setBankData = (state, action) => {
    return updateObject(state, {bankData: action.data});
}

const setBankAccountStatusesData = (state, action) => {
    return updateObject(state, {bankAccountStatuses: action.data});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SET_GENERAL_BANK_DATA': return setBankData(state, action);
        case 'SET_GENERAL_BANK_ACCOUNT_STATUSES_DATA': return setBankAccountStatusesData(state, action);
        default: return state;
    }
};

export default reducer;