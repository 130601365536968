import { updateObject } from '../../utility';

const initFormData = {
    bankName: null,
    bankAccountName: {
        value: ''
    },
    bankAccountNumber: {
        value: '',
    }
};
const initialState = {
    data: null,
    formData: initFormData,
    isSubmited: false
}

const setInit = (state, action) => {
    let formData = {...state.formData};
    if (action.payload.item.bankname) {
      formData['bankName'] = {...formData['bankName'], ...{value: action.payload.item.bankname, label: action.payload.item.bankname}};
    } else {
      formData['bankName'] = null;
    }
    formData['bankAccountName'] = {...formData['bankAccountName'], ...{value: action.payload.item.bankaccname}};
    formData['bankAccountNumber'] = {...formData['bankAccountNumber'], ...{value: action.payload.item.bankaccno}};

    return updateObject(state, {formData, isSubmited: false});
}

const setData = (state, action) => {
    return updateObject(state, {data: action.data});
}

const inputChanged = (state, action) => {
    let identifier = action.payload.identifier;
    let value = action.payload.value;
    let formData = {...state.formData};
    if (identifier === 'bankName') {
      formData[identifier] = {...formData[identifier], ...{value, label: value}};
    } else {
      formData[identifier] = {...formData[identifier], ...{value}};
    }
    return updateObject(state, {formData});
}

const setIsSubmit = (state, action) => {
    return updateObject(state, {formData: initFormData, isSubmited: action.value});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SET_USER_BANK_ACCOUNT_DATA': return setData(state, action);
        case 'USER_BANK_ACCOUNT_INPUT_CHANGED': return inputChanged(state, action);
        case 'USER_BANK_ACCOUNT_SUBMIT_SUCCESS': return setIsSubmit(state, action);
        case 'USER_BANK_ACCOUNT_INIT': return setInit(state, action);
        default: return state;
    }
};

export default reducer;
