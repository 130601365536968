import { updateObject } from '../utility';

const initialState = {
    userData: null,
}

const setUserData = (state, action) => {
    return updateObject(state, {userData: action.userData.data});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SET_USER_DATA': return setUserData(state, action);
        default: return state;
    }
};

export default reducer;
