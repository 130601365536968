import { updateObject } from '../utility';
const initFormData = {
    id: {
        value: ''
    },
    userName: {
        value: ''
    },
    transNo: {
        value: ''
    },
    status: {
        value: 1
    },
    staffName: {
        value: '',
    }
}

const initialState = {
    data: null,
    formData: initFormData,
    isSubmited: false
}

const setData = (state, action) => {
    return updateObject(state, {data: action.data});
}

const setInit = (state, action) => {
    let formData = {...state.formData};
    formData['id'] = {...formData['id'], ...{value: action.item.id}};
    formData['userName'] = {...formData['userName'], ...{value: action.item.user_name}};
    formData['transNo'] = {...formData['transNo'], ...{value: action.item.transno}};
    formData['status'] = {...formData['status'], ...{value: action.item.status}};
    formData['staffName'] = {...formData['staffName'], ...{value: (action.item.staffname)? action.item.staffname: ''}};

    return updateObject(state, {formData, isSubmited: false});
}

const inputChanged = (state, action) => {
    let identifier = action.payload.identifier;
    let value = action.payload.value;
    let formData = {...state.formData};
    formData[identifier] = {...formData[identifier], ...{value}};

    return updateObject(state, {formData});
}

const setIsSubmit = (state, action) => {
    return updateObject(state, {isSubmited: action.value});
}


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SET_DEPOSIT_DATA': return setData(state, action);
        case 'DEPOSIT_INPUT_CHANGED': return inputChanged(state, action);
        case 'DEPOSIT_SUBMIT_SUCCESS': return setIsSubmit(state, action);
        case 'DEPOSIT_INIT': return setInit(state, action);
        default: return state;
    }
};

export default reducer;
