const UserDetails = (state = '', action) => {
   switch (action.type) {
      case "USER_DETAILS":
         return action.id;
      default:
         return state;
   }
};

export default UserDetails;
