import { NOTIF_START_TIMER, NOTIF_SETDATA, NOTIF_SETREADING } from "../../constants/action-types";

const initialState = {
  data: {
    unread: 0,
    changes: false,
    first: true,
    data: []
  },
  timer: null,
  delay: 5000,
  reading: false,
};

function notificationReducer(state = initialState, action) {
  if (action.type === NOTIF_START_TIMER) {
    return Object.assign({}, state, {
      timer: action.timer,
    });
  } else if (action.type === NOTIF_SETDATA) {
    const newState = {
      data: action.data,
    };
    if (state.data.first) newState.data.first = false;
    return Object.assign({}, state, newState);
  } else if (action.type === NOTIF_SETREADING) {
    return Object.assign({}, state, {
      reading: action.reading,
    });
  }
  return state;
}

export default notificationReducer;
