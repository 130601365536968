import { updateObject } from '../utility';

const initialState = {
    formData: {
        vipLevel: {
            value: 6
        },
        userType: {
            value: 1
        },
        isActive: {
            value: false,
        },
        isAntiDeactive: {
            value: true
        },
        isLoyalCustomer: {
            value: false
        },
        isPhoneVerify: {
            value: false
        },
        isEmailVerify: {
            value: false
        }
    },
    isSubmited: false
}

const setInit = (state, action) => {
    let formData = {...state.formData};
    formData['vipLevel'] = {...formData['vipLevel'], ...{value: action.item.viplvl_id}};
    formData['userType'] = {...formData['userType'], ...{value: action.item.usertype_id}};
    formData['isActive'] = {...formData['isActive'], ...{value: action.item.isActive}};
    formData['isAntiDeactive'] = {...formData['isAntiDeactive'], ...{value: Boolean(action.item.antideactivated)}};
    formData['isLoyalCustomer'] = {...formData['isLoyalCustomer'], ...{value: Boolean(action.item.isloyal)}};
    formData['isPhoneVerify'] = {...formData['isPhoneVerify'], ...{value: action.item.phoneverified}};
    formData['isEmailVerify'] = {...formData['isEmailVerify'], ...{value: action.item.emailverified}};

    return updateObject(state, {formData, isSubmited: false});
}

const inputChanged = (state, action) => {
    let identifier = action.payload.identifier;
    let value = action.payload.value;
    let formData = {...state.formData};
    formData[identifier] = {...formData[identifier], ...{value}};

    return updateObject(state, {formData});
}

const setIsSubmit = (state, action) => {
    return updateObject(state, {isSubmited: action.value});
}


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'EDIT_USER_INPUT_CHANGED': return inputChanged(state, action);
        case 'EDIT_USER_SUBMIT_SUCCESS': return setIsSubmit(state, action);
        case 'EDIT_USER_INIT': return setInit(state, action);
        default: return state;
    }
};

export default reducer;