import { CONFIG_SETAPIENDPOINTS, CONFIG_SETNUMBERFORMAT, CONFIG_SETAPIBASEURL, CONFIG_SETAPIENV } from "../../constants/action-types";

const initialState = {
  localStorageEnabled: typeof localStorage !== 'undefined' && localStorage ? true : false,
  apiEndpoints: null,
  numberFormat: null,
  apiBaseUrl: '',
  apiEnv: null
};

function configReducer(state = initialState, action) {
  if (action.type === CONFIG_SETAPIENDPOINTS) {
    return Object.assign({}, state, {
      apiEndpoints: action.payload
    });
  } else if (action.type === CONFIG_SETNUMBERFORMAT) {
    return Object.assign({}, state, {
      numberFormat: action.payload
    });
  } else if (action.type === CONFIG_SETAPIBASEURL) {
    return Object.assign({}, state, {
      apiBaseUrl: action.payload
    });
  } else if (action.type === CONFIG_SETAPIENV) {
    return Object.assign({}, state, {
      apiEnv: action.key
    });
  }
  return state;
}

export default configReducer;
