// auth
export const AuthLocalStorageKey = 'auth';
export const AUTH_SIGNIN = "AUTH_SIGNIN";
export const AUTH_SIGNOUT = "AUTH_SIGNOUT";
export const AUTH_CHECK = "AUTH_CHECK";
export const AUTH_CHECK_FINISHED = "AUTH_CHECK_FINISHED";
export const AUTH_SETTOKEN = "AUTH_SETTOKEN";

// config
export const CONFIG_SETAPIENDPOINTS = "CONFIG_SETAPIENDPOINTS";
export const CONFIG_SETNUMBERFORMAT = "CONFIG_SETNUMBERFORMAT";
export const CONFIG_SETAPIBASEURL = "CONFIG_SETAPIBASEURL";
export const CONFIG_SETAPIENV = "CONFIG_SETAPIENV";

// notification
export const NOTIF_START_TIMER = "NOTIF_START_TIMER";
export const NOTIF_SETDATA = "NOTIF_SETDATA";
export const NOTIF_SETREADING = "NOTIF_SETREADING";
