import { updateObject } from '../utility';

const initialState = {
    data: null,
}

const setUserTypeData = (state, action) => {
    return updateObject(state, {data: action.userTypeData});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SET_USER_TYPE_DATA': return setUserTypeData(state, action);
        default: return state;
    }
};

export default reducer;