import { AUTH_SIGNIN, AUTH_SIGNOUT, AUTH_CHECK, AUTH_CHECK_FINISHED, AUTH_SETTOKEN } from "../../constants/action-types";

const initialState = {
  user: null,
  checking: false,
  checkPromise: null,
  token: '',
};

function authReducer(state = initialState, action) {
  if (action.type === AUTH_SIGNIN) {
    return Object.assign({}, state, {
      user: action.payload
    });
  } else if (action.type === AUTH_SIGNOUT) {
    return Object.assign({}, state, {
      user: null,
      token: '',
    });
  } else if (action.type === AUTH_CHECK) {
    return Object.assign({}, state, {
      checking: true,
      checkPromise: action.payload,
    });
  } else if (action.type === AUTH_CHECK_FINISHED) {
    return Object.assign({}, state, {
      checking: false,
      checkPromise: null,
    });
  } else if (action.type === AUTH_SETTOKEN) {
    return Object.assign({}, state, {
      token: action.payload,
    });
  }
  return state;
}

export default authReducer;
