import { updateObject } from '../utility';

const initialState = {
    data: null,
    formData: [],
    isSubmit: false
}

const setData = (state, action) => {
    return updateObject(state, {data: action.data});
}

const initForm = (state, action) => {
    let formData = [...state.formData];
    for (let key in action.data) {
        formData[key] = {
            id: action.data[key].bank_id,
            accountNumber: action.data[key].accno,
            accountName: (action.data[key].account_name)? action.data[key].account_name:'',
            status: action.data[key].status,
            initialBalance: action.data[key].initialbalance,
            imagePath: action.data[key].bank.image_path,
            bankName: action.data[key].bank.name,
        }
    }
    return updateObject(state, {formData, isSubmited:false});
}

const inputChanged = (state, action) => {
    let key = action.payload.key;
    let identifier = action.payload.identifier;
    let value = action.payload.value;
    let formData = [...state.formData];
    formData[key][identifier] = value;

    return updateObject(state, {formData, isSubmited:false});
}

const setIsSubmit = (state, action) => {
    return updateObject(state, {isSubmited: action.value});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SET_BANK_ACCOUNT_DATA': return setData(state, action);
        case 'SET_BANK_ACCOUNT_INIT_FORM': return initForm(state, action);
        case 'BANK_ACCOUNT_INPUT_CHANGED': return inputChanged(state, action);
        case 'BANK_ACCOUNT_SUBMIT_SUCCESS': return setIsSubmit(state, action);
        default: return state;
    }
};

export default reducer;