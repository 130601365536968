const users = (state = [], action) => {
   switch (action.type) {
      case "ADD_USER":
         return [
            ...state,
            {
               id: action.id,
               name: action.name,
               email: action.email,
               starred: false,
               deleted: false,
            }
         ];

      case "UPDATE_USER":
         return state.map(user => (user.id === action.id ? { ...user, [action.field]: action.value } : user));

      case "TOGGLE_STARRED_USER":
         return state.map(user => (user.id === action.id ? { ...user, starred: !user.starred } : user));

      case "DELETE_USER":
         return state.map(user => (user.id === action.id ? { ...user, deleted: !user.deleted } : user));

      case "SET_USERS":
        return action.users;

      default:
         return state;
   }
};

export default users;
